import React, { useState } from 'react';
import './modalCPF.module.css';
import InputMask from 'react-input-mask';

import { editaUsuarioDB } from '../../services/authFirestore';

export default function ModalCPF (params) {

    const [cpf, setCPF] = useState("");
    const [avisaCPF, setAvisaCPF] = useState(false);
    const [mask, setMask] = useState("999.999.999-99");

    const handleCPF = (event) => {
        setCPF(event.target.value);
    }

    const salvarCPF = async () => {
        setAvisaCPF(false);

        const responseCPFValido = await validarCPF(cpf);

        if(!responseCPFValido){
            alert("CPF não é válido! Tente novamente...")
            return;
        }

        const responseEditaUsuario = await editaUsuarioDB(params.idUsuario, {cpf: cpf});

        if(!responseEditaUsuario.success){
            alert("CPF não foi cadastrado! Tente novamente...")
            return;
        }

        params.onClick();
    }

    const validarCPF = (cpf) => {
        // Remove caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');
    
        // Verifica se o CPF tem 11 dígitos
        if (cpf.length !== 11) {
            return false;
        }
    
        // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
        if (/^(\d)\1{10}$/.test(cpf)) {
            return false;
        }
    
        // Calcula o primeiro dígito verificador
        let soma = 0;
        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let resto = soma % 11;
        let digito1 = resto < 2 ? 0 : 11 - resto;
    
        // Calcula o segundo dígito verificador
        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpf.charAt(i)) * (11 - i);
        }
        resto = soma % 11;
        let digito2 = resto < 2 ? 0 : 11 - resto;
    
        // Verifica se os dígitos calculados são iguais aos fornecidos
        if (digito1 !== parseInt(cpf.charAt(9))) {
            return false;
        }

        if (digito2 !== parseInt(cpf.charAt(10))) {
            return false;
        }
    
        return true;
    }
    

    return(
        <div className='containerModal'>
            <div className='modal'>
                <div style={{justifyContent: "space-between", display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10}}>
                    <h4>Cadastro CPF</h4>
                    <h2 onClick={() => params.onClick()} style={{color: "grey", cursor: "pointer"}}>x</h2>
                </div>
                <InputMask
                    mask={mask}
                    value={cpf} onChange={handleCPF} placeholder={"XXX.XXX.XXX-XX"}
                    style={{
                        width:  330, 
                        height: 30,
                        borderRadius: 10,
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingLeft: 10,
                        fontSize: 22,
                        backgroundColor: 'black',
                        opacity: 0.9,
                        color: 'white',
                        marginBottom: 14, 
                        borderBottomWidth: avisaCPF ? 3 : 0,
                        borderBottomColor: 'red',
                        borderEndEndRadius: avisaCPF && 0,
                        borderEndStartRadius: avisaCPF && 0}}
                    onBlur={e => {
                        if (e.target.value.replace("_", "").length === 14) {
                            setMask("999.999.999-99");
                        }
                    }}
                    onFocus={e => {
                        if (e.target.value.replace("_", "").length === 14) {
                            setMask("999.999.999-99");
                        }
                    }}
                >
                    {inputProps => <input {...inputProps} type="tel" />}
                </InputMask>
                <div 
                    style={{backgroundColor: "#690B23", width: 310, alignSelf: 'center'}}
                    className='containerBotao' 
                    onClick={salvarCPF}
                >
                    <span style={{backgroundColor: "#690B23"}} className='txtBotao'>Cadastrar!</span>
                </div>
            </div>
        </div>
    )
}