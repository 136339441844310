import { useState } from 'react';
import './botaoImagem.css';
import { isMobile } from 'react-device-detect';

import { LiaWineBottleSolid } from "react-icons/lia";
import { TbFileDescription } from "react-icons/tb";

export default function BotaoImagem(params) {
    
    const [deveOcultar, setDeveOcultar] = useState(true);

    const qtdGarrafas = 
        params.nomePacote == 'Sommelier Iniciante' 
            ? 1 
            : params.nomePacote == 'Sommelier Avançado' 
                ? 2
                : 3

    const elementos = [];
  
    for (let i = 0; i < qtdGarrafas; i++) {
        elementos.push(
            <LiaWineBottleSolid
                color='#FFF'
                size={40} 
                style={{marginBottom: 8}}
            />
        );
    }

    return(
        <div 
            className='containerBotaoImg' 
            style={{backgroundColor: params.corBotao}}
            onClick={() => {
                params.onClick();
            }}
            onMouseEnter={() => setDeveOcultar(false)}
            onMouseLeave={() => setDeveOcultar(true)}
        >   
            <div className='divItensLinha' style={{ display: (deveOcultar && !isMobile) ? 'none' : 'flex' }}>
                <div className='divItens'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        { elementos }
                    </div>
                    <span className='txtPacotePreco'>{qtdGarrafas} Garrafa{qtdGarrafas > 1 && "(s)"}</span>
                </div>
                <div className='divItens'>
                    <TbFileDescription
                        color='#FFF'
                        size={40} 
                        style={{marginBottom: 8}}
                    />
                    <span className='txtPacotePreco'>{"Carta de "}</span>
                    <span className='txtPacotePreco'>{"degustação"}</span>
                </div>

            </div>
            <img
                className="imagemFundoBotao"
                src={params.imagemFundo} 
                alt="Garrafas de vinho"
                style={{width: (deveOcultar && !isMobile) ? "450px" : '300px' }}
            />
            <div className='divPrecoNome'>
                <p><span className='txtPacote'>{params.nomePacote}</span></p>
                <p><span className='txtPacotePreco'>R$ {params.valorPacote.toFixed(2).replace('.',',')}</span></p>
            </div>
        </div>
    )
}