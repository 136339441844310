import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
  getDocs
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "./firebase";

export const getCheckoutUrl = async (priceId) => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  const db = getFirestore(app);
  const checkoutSessionRef = collection(
    db,
    "customers",
    userId,
    "checkout_sessions"
  );

  const docRef = await addDoc(checkoutSessionRef, {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        console.log("Stripe Checkout URL:", url);
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getPortalUrl = async () => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  let dataWithUrl;
  try {
    const functions = getFunctions(app, "southamerica-east1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    // Add a type to the data
    dataWithUrl = data;
    console.log("Reroute to Stripe portal: ", data);
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};

export const verificaAssinaturaUsuario = async () => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User not logged in");

  const db = getFirestore(app);
  const subscriptionsRef = collection(db, "customers", userId, "subscriptions");
  const q = query(
    subscriptionsRef,
    where("status", "in", ["trialing", "active"])
  );

  return new Promise((resolve, reject) => {

    let produtoAssinado = "";

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        // In this implementation we only expect one active or trialing subscription to exist.
        snapshot.docs.forEach((element) => {
          // console.log("element - ", element.data().items[0].plan.product);
          element.data().items.forEach(element => {
            if(element.plan){
              produtoAssinado = element.price.product.name;
            }
          });
        })
        if (snapshot.docs.length === 0) {
          // console.log("No active or trialing subscriptions found");
          resolve(produtoAssinado);
        } else {
          // console.log("Active or trialing subscription found");
          resolve(produtoAssinado);
        }
        unsubscribe();
      },
      reject
    );
  });
};

export const listaProdutos = async () => {
  const db = getFirestore(app);
  const querySnapshot = await getDocs(collection(db, "products"));

  const listaDeProdutosComValores = [];

  // Criar um array de Promises para cada documento
  const promises = querySnapshot.docs.map(async (doc) => {
    const productPrice = collection(db, "products", doc.id, "prices");

    const querySnapshotPrices = await getDocs(collection(db, "products", doc.id, "prices"));
    let priceId = "";
    querySnapshotPrices.forEach((docPrice) => {
      priceId = docPrice.id;
    });

    // Retornar uma Promise que resolve quando o onSnapshot é concluído
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        productPrice,
        (snapshot) => {
          const precosProdutos = snapshot?.docs[0]?.data();

          if (precosProdutos && precosProdutos.type === "recurring") {
            const valorFloat = precosProdutos.unit_amount / 100;

            listaDeProdutosComValores.push({
              idProduto: precosProdutos.product,
              idPrice: priceId,
              nomeProduto: doc.data().name,
              active: doc.data().active,
              valorUnitario: valorFloat,
            });
          }

          unsubscribe(); 
          resolve(); 
        },
        reject 
      );
    });
  });

  await Promise.all(promises);

  return listaDeProdutosComValores;
};